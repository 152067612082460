import React, { useEffect } from "react"

import Modal, { Dialog } from "@app/components/Modal"
import api from "@app/services/api"
import {
  Checkbox,
  FormItem,
  Input,
  Select,
  useForm,
} from "@app/components/Form"
import { handleApiError } from "@app/utils"
import css from "./UpdateCreateMappingModal.module.scss"

type PropsType = {
  vendorIntegrationId: string
  show: boolean
  onHide: () => void
  mutate: () => void
  selectedMapping: VendorIntegrationMapping | undefined
  unmappedItem?: boolean
}

type FormFieldsType = {
  mapping_type: string
  internal_id: string
  external_id: string
  external_name: string
  inactive: boolean
}

export default ({
  vendorIntegrationId,
  selectedMapping,
  onHide,
  show,
  mutate,
  unmappedItem,
}: PropsType) => {
  const { control, handleModalSubmit, reset } = useForm<FormFieldsType>({
    schema: (yup) => {
      return yup.lazy(() =>
        yup.object().shape({
          mapping_type: yup.string().required("Mapping type is required."),
          internal_id: yup.string().required("Internal ID is required."),
          external_id: yup.string().required("External ID is required."),
          external_name: yup.string().required("External name is required."),
          inactive: yup.boolean(),
        })
      )
    },
    defaultValues: selectedMapping,
  })

  useEffect(() => {
    if (selectedMapping) {
      reset(selectedMapping)
    } else {
      reset({})
    }
  }, [selectedMapping])

  const onSubmit = (mapping: FormFieldsType) => {
    const { mapping_type, internal_id, external_id, external_name, inactive } =
      mapping

    if (selectedMapping) {
      api
        .updateVendorIntegrationMapping(selectedMapping.id, {
          mapping_type,
          internal_id,
          external_id,
          external_name,
          inactive,
        })
        .then((_res) => {
          mutate()
          onHide()
        }, handleApiError)
    } else {
      api
        .createVendorIntegrationMapping({
          mapping_type,
          internal_id,
          external_id,
          external_name,
          inactive,
          vendor_integrationid: vendorIntegrationId,
        })
        .then((_res) => {
          mutate()
          onHide()
          reset({})
        }, handleApiError)
    }
  }

  const deleteMapping = () => {
    if (selectedMapping) {
      Dialog.confirm({
        title: "Are you sure you want to delete?",
        ok() {
          api
            .deleteVendorIntegrationMapping(selectedMapping.id)
            .then((_res) => {
              onHide()
              mutate()
            }, handleApiError)
        },
      })
    }

    return false
  }

  return (
    <Modal
      title={selectedMapping ? "Update Mapping" : "Create New Mapping"}
      show={show}
      onHide={onHide}
      className={css.customModalStyles}
      buttons={
        selectedMapping
          ? [
              ...(unmappedItem
                ? []
                : [
                    {
                      text: "Delete",
                      variant: "danger",
                      size: "sm" as "sm",
                      onClick: deleteMapping,
                    },
                  ]),
              {
                text: "Cancel",
                variant: "outline-secondary",
                size: "sm",
                onClick: onHide,
              },
              {
                control,
                text: "Save",
                variant: "primary",
                size: "sm",
                onClick: handleModalSubmit(onSubmit),
              },
            ]
          : [
              {
                control,
                text: "Submit",
                variant: "primary",
                size: "sm",
                onClick: handleModalSubmit(onSubmit),
              },
              {
                text: "Cancel",
                variant: "outline-secondary",
                size: "sm",
                onClick: onHide,
              },
            ]
      }
    >
      <FormItem label="Integration ID">
        <Input value={vendorIntegrationId} disabled />
      </FormItem>
      <FormItem
        label="Mapping Type"
        name="mapping_type"
        control={control}
        required
      >
        <Select
          options={[
            {
              label: "User",
              value: "user",
            },
            {
              label: "Group",
              value: "group",
            },
            {
              label: "Job",
              value: "job",
            },
          ]}
          labelKey="label"
          valueKey="value"
        />
      </FormItem>
      <FormItem
        label="Internal ID"
        name="internal_id"
        control={control}
        required
      >
        <Input />
      </FormItem>
      <FormItem
        label="External ID"
        name="external_id"
        control={control}
        required
      >
        <Input />
      </FormItem>
      <FormItem
        label="External Name"
        name="external_name"
        control={control}
        required
      >
        <Input />
      </FormItem>
      <FormItem label="Inactive" name="inactive" control={control}>
        <Checkbox />
      </FormItem>
    </Modal>
  )
}
